<template lang="pug">
.code-insert-platform-connect-options.w-100
  om-select-card.w-100.p-0.col-md-12(vertical arrow value="platform" @click="navigateToSetup()")
    template(#title)
      .d-flex.align-items-center
        .brand-select-card-title(v-html="optionTitle()")
        om-chip.ml-3(color="secondary") {{ $t('recommended') }}
    template(#backgroundContent)
      span
    template(#iconContent)
      img(:src="require(`@/assets/admin/svg/insertcode/option_${illustration}.svg`)")
  om-select-card.w-100.p-0.col-md-12(
    vertical
    arrow
    :title="optionTitle('tagmanager', 'installWith')"
    @click="navigateToSetup('tagmanager')"
  )
    template(#title)
      .brand-select-card-title(v-html="optionTitle('tagmanager', 'installWith')")
    template(#backgroundContent)
      span
    template(#iconContent)
      img(:src="require(`@/assets/admin/svg/insertcode/option_gtag.svg`)")
</template>

<script>
  export default {
    props: {
      platform: { type: String, default: null },
    },
    computed: {
      illustration() {
        return ['custom', 'other', 'manual'].includes(this.platform) ? 'html' : this.platform;
      },
      isCustomPlatform() {
        return ['custom', 'other'].includes(this.platform);
      },
    },
    methods: {
      optionTitle(platform, type = 'installationGuideTo') {
        if (!platform && this.isCustomPlatform) return this.$t('codeInsertV2.iCanInsert');
        return this.$t(`codeInsertV2.titles.${type}`, {
          platform: this.$t(`codeInsertV2.${platform || this.platform}.name`),
        });
      },
      navigateToSetup(override) {
        const platform = override || this.platform;
        const { name, params, query } = this.$route;

        this.$router.push({
          name: `${name}-setup`,
          params: { ...params, platform },
          query: { ...query },
        });
      },
    },
  };
</script>
